@import url('https://fonts.googleapis.com/css2?family=Chivo:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Inter:wght@300;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.parr-text {
    @apply font-primary text-lg lg:text-2xl text-zinc tracking-wide lg:leading-loose;
}

.display1-text {
    @apply font-primary font-bold text-zinc text-5xl lg:text-7xl;
}

.display2-text {
    @apply font-primary font-bold text-zinc text-2xl lg:text-5xl lg:leading-normal;
}

.highlighted-text {
    @apply font-primary text-zinc font-bold text-2xl lg:text-3xl xl:text-4xl leading-9 lg:leading-relaxed xl:leading-relaxed;
}

.sec-name {
    @apply font-secondary text-zinc font-semibold lg:text-lg
}

.btn-primary {
    @apply text-cyannarv-300 font-secondary font-semibold hover:text-cyannarv-500 transform hover:scale-105;
}

.bg-gradient-t {
    @apply bg-gradient-to-t from-white from-10% to-cyannarv-20 to-90%
}

.bg-gradient-b {
    @apply bg-gradient-to-b from-white from-20% to-cyannarv-20 to-90%
}

.bg-gradient-r {
    @apply bg-gradient-to-r from-white from-60% to-cyannarv-20 to-100%
}

.title-text {
    @apply font-primary text-lg lg:text-3xl text-zinc tracking-wide lg:leading-loose;
}